// import { sysName } from '../config';

export const types = {
  UPLOAD_CDN: 'TOOLS/UPLOAD_CDN',
  REFRESH_CDN: 'TOOLS/REFRESH_CDN',
  IMAGE_MIN: 'TOOLS/IMAGE_MIN'
};
export const actions = {
  uploadCDN: (resolve, values) => ({
    type: types.UPLOAD_CDN,
    values,
    resolve
  }),
  refreshCDN: (resolve, values) => ({
    type: types.REFRESH_CDN,
    values,
    resolve
  }),
  imageMin: (resolve, values, quality) => ({
    type: types.IMAGE_MIN,
    values,
    quality,
    resolve
  })
};

const initState = {};

export default (state = initState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
