import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  // Menu,
  MenuList,
  MenuItem,
  Avatar
} from '@mui/material';
import {
  PersonOutlined as UserIcon,
  PowerSettingsNew as LogoutIcon,
  Apps as AppsIcon,
  LockReset as LockResetIcon,
  Portrait as PortraitIcon,
  CloudUploadOutlined as CloudUploadIcon,
  CloudSyncOutlined as RefreshIcon,
  Compress as CompressIcon,
  WorkOutlineOutlined as WorkIcon
} from '@mui/icons-material';
import { types as accountTypes } from '../reducers/account';
import compose from 'recompose/compose';
import { pathTitle, sysName } from '../config';
import NxPopper from '../components/nxPopper';

// 个人信息菜单项配置
const infoMenuItems = [
  {
    name: '个人信息',
    to: '/sso/account/info',
    icon: PortraitIcon
  },
  {
    name: '修改密码',
    to: '/sso/account/password',
    icon: LockResetIcon
  },
  {
    name: '绑定管理',
    to: '/sso/auth/mybinds',
    icon: AppsIcon
  }
];

// 工具菜单项配置
const ToolsMenuItems = [
  {
    name: 'CDN上传',
    to: '/tools/cdn',
    icon: CloudUploadIcon
  },
  {
    name: 'CDN刷新',
    to: '/tools/cdnRefresh',
    icon: RefreshIcon
  },
  {
    name: '图片压缩',
    to: '/tools/imagemin',
    icon: CompressIcon
  }
];

// 带样式的Typography组件
const TypographyTitle = styled(Typography)(() => ({
  flex: 'auto',
  color: '#fff',
  fontSize: '1.6rem'
}));

// 带样式的Button组件
const ToolbarBtn = styled(Button)(() => ({
  fontSize: '1.5rem',
  color: '#fff',
  '&:hover': {
    background: 'rgba(0, 0, 0, 0.1)'
  }
}));

// 带样式的头像组件
const AvatarImage = styled(Avatar)(() => ({
  marginRight: '5px',
  width: '34px',
  height: '34px'
}));

function mapStateToProps(state) {
  return {
    username: state.account.username,
    role: state.account.role,
    info: state.account.info
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => {
      dispatch({ type: accountTypes.SAGA_LOGOUT_REQUEST, toLogin: true });
    }
  };
}

class AppHeader extends React.Component {
  state = {
    anchorEl: null,
    toolsEl: null
  };

  handleClick = event => {
    if (this.props.username) {
      this.setState({ anchorEl: event.currentTarget, toolsEl: null });
    } else {
      console.log('请登录 :>> ');
    }
  };
  handleClose = () => {
    this.setState({ anchorEl: null, toolsEl: null });
  };
  handleToolsClick = event => {
    if (this.props.username) {
      this.setState({ anchorEl: null, toolsEl: event.currentTarget });
    } else {
      console.log('请登录 :>> ');
    }
  };

  render() {
    const {
      // onMenuClick,
      logout,
      color,
      location: { pathname },
      username,
      role,
      info: { basic = {} }
    } = this.props;
    const { anchorEl, toolsEl } = this.state;
    document.title = `${pathTitle[pathname]} - ${sysName}`;

    const wxinfo = basic.wxinfo ? JSON.parse(basic.wxinfo) : {};
    const isWechatLogin =
      basic && basic.lastLoginType === 'wechat' && wxinfo.nickname;

    // console.log('basic role :>> ', basic, role, anchorEl);
    return (
      <AppBar
        variant="elevation"
        enableColorOnDark
        color={color}
        position="relative"
      >
        <Toolbar
          sx={{
            display: 'flex',
            minHeight: '50px !important'
          }}
          className="header-toolbar"
        >
          <TypographyTitle variant="h6">{pathTitle[pathname]}</TypographyTitle>
          <ToolbarBtn
            aria-haspopup="true"
            title="账号"
            onClick={this.handleClick}
            onMouseOver={this.handleClick}
          >
            {isWechatLogin ? ( // 最后一次是微信登录，用微信头像和微信昵称
              <AvatarImage alt="Remy Sharp" src={wxinfo.headimgurl} />
            ) : (
              <UserIcon />
            )}
            {isWechatLogin ? wxinfo.nickname : username || '未登录'}
          </ToolbarBtn>
          {anchorEl && (
            <NxPopper
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              handleClose={this.handleClose}
            >
              <MenuList
                autoFocusItem={Boolean(anchorEl)}
                onClick={this.handleClose}
                // onKeyDown={handleListKeyDown}
              >
                {infoMenuItems.map(item => (
                  <MenuItem
                    key={item.name}
                    component={Link}
                    to={item.to}
                    sx={{ color: '#333' }}
                  >
                    <item.icon sx={{ mr: '.5rem' }} />
                    {item.name}
                  </MenuItem>
                ))}
              </MenuList>
            </NxPopper>
          )}
          {username &&
          ['admin', 'staff'].indexOf(role) > -1 && ( // 员工、管理员才可使用工具箱
              <ToolbarBtn
                aria-haspopup="true"
                title="账号"
                onClick={this.handleToolsClick}
                onMouseOver={this.handleToolsClick}
              >
                <WorkIcon />
                工具箱
              </ToolbarBtn>
            )}
          {toolsEl && (
            <NxPopper
              open={Boolean(toolsEl)}
              anchorEl={toolsEl}
              handleClose={this.handleClose}
            >
              <MenuList
                autoFocusItem={Boolean(toolsEl)}
                onClick={this.handleClose}
                // onKeyDown={handleListKeyDown}
              >
                {ToolsMenuItems.map(item => (
                  <MenuItem
                    key={item.name}
                    component={Link}
                    to={item.to}
                    sx={{ color: '#333' }}
                  >
                    <item.icon sx={{ mr: '.5rem' }} />
                    {item.name}
                  </MenuItem>
                ))}
              </MenuList>
            </NxPopper>
          )}
          <ToolbarBtn onClick={logout}>
            <LogoutIcon />
            注销
          </ToolbarBtn>
        </Toolbar>
      </AppBar>
    );
  }
}

AppHeader.propTypes = {
  onMenuClick: PropTypes.func,
  title: PropTypes.string,
  logout: PropTypes.func,
  color: PropTypes.string,
  type: PropTypes.oneOf(['user', 'admin'])
};

AppHeader.defaultProps = {
  color: 'primary',
  type: 'user'
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AppHeader);
