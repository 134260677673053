import { fork, take, put, call, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { md5Passwd } from '../services/utility';
import { stopSubmit } from 'redux-form';
// import history from '../history';
import { types as ssoTypes } from '../reducers/sso';
import { types as accountTypes } from '../reducers/account';
import { actions as accountActions } from '../reducers/account';

function* authLogin() {
  while (true) {
    const {
      resolve,
      values,
      from,
      redirect,
      token
      // , authOk
    } = yield take(ssoTypes.SAGA_AUTH_LOGIN_REQUEST);
    const username = values.username.toLowerCase();
    const password = md5Passwd(values.password);
    const remember = !!values.remember;
    const response = yield axios.post('/sso/auth/login', {
      username,
      password,
      remember,
      from,
      redirect,
      token
    });
    if (response.code === 200) {
      yield call(resolve);
      yield put(
        accountActions.loginSuccess(
          response.data.id,
          username,
          response.data.active,
          response.data.role
        )
      );
      // const location = `${authOk}?token=${response.data.token}&redirect=${
      //   response.data.redirect
      // }`;
      const location = `${redirect}?jwt=${response.data.token}`;
      global.location = location;
    } else {
      yield put(stopSubmit('authLoginForm', { _error: response.error }));
    }
  }
}

// 使用当前用户直接登录
function* authUseCurrentUser() {
  while (true) {
    const {
      resolve,
      from,
      redirect,
      token
      // , authOk
    } = yield take(ssoTypes.SAGA_AUTH_USE_CURRENT_USER_REQUEST);
    const response = yield axios.post('/sso/auth/usecurrentuser', {
      from,
      redirect,
      token
    });
    if (response.code === 200) {
      yield call(resolve);
      // const location = `${authOk}?token=${response.data.token}&redirect=${
      //   response.data.redirect
      // }`;
      const location = `${redirect}?jwt=${response.data.token}`;
      global.location = location;
    } else {
      yield put(stopSubmit('authLoginForm', { _error: response.error }));
    }
  }
}

function* authBindLogin() {
  while (true) {
    const {
      resolve,
      values,
      from,
      redirect,
      token
      // , authOk
    } = yield take(ssoTypes.SAGA_AUTH_BIND_REQUEST);
    const username = values.username.toLowerCase();
    const password = md5Passwd(values.password);
    const response = yield axios.post(
      '/sso/auth/user/bind',
      {
        username,
        password,
        from,
        redirect,
        token
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    if (response.code === 200) {
      yield call(resolve);
      yield put(
        accountActions.loginSuccess(
          response.data.id,
          username,
          response.data.active,
          response.data.role
        )
      );
      // const location = `${authOk}?token=${response.data.token}&redirect=${
      //   response.data.redirect
      // }`;
      const location = `${redirect}?jwt=${response.data.token}`;
      global.location = location;
    } else {
      yield put(stopSubmit('authLoginForm', { _error: response.error }));
    }
  }
}

function* getBindsFlow() {
  while (true) {
    yield take(accountTypes.SAGA_GET_BINDS_REQUEST);
    const res = yield axios.get('/sso/auth/user/binds');
    if (res.code === 200) {
      yield put(accountActions.setUserBinds(res.data));
    }
  }
}

function* toggleBindSaga(action) {
  const { id, agreed } = action;
  const res = yield axios.post('/sso/auth/user/bind/toggle', { id, agreed });
  if (res.code === 200) {
    yield put(accountActions.setUserBinds(res.data));
  }
}

function* toggleBindFlow() {
  // while (true) {
  yield takeLatest(accountTypes.SAGA_TOGGLE_BIND_REQUEST, toggleBindSaga);
  // }
}

export default [
  fork(authLogin),
  fork(authUseCurrentUser),
  fork(authBindLogin),
  fork(getBindsFlow),
  fork(toggleBindFlow)
];
