import React from 'react';
import { styled } from '@mui/material/styles';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import RegForm from '../../forms/account/RegForm';
import { types as accountTypes } from '../../reducers/account';
import { actions as commonActions } from '../../reducers/common';
import { Card, CardContent, Typography } from '@mui/material';
import grey from '@mui/material/colors/grey';
import compose from 'recompose/compose';

const PREFIX = 'Reg';

const classes = {
  card: `${PREFIX}-card`,
  title: `${PREFIX}-title`,
  buttomText: `${PREFIX}-buttomText`
};

const Root = styled('div')(({ theme }) => ({
  height: '80%',
  display: 'flex',
  [`& .${classes.card}`]: {
    width: '30rem',
    margin: 'auto'
  },

  [`& .${classes.title}`]: { ...theme.typography.title3 },

  [`& .${classes.buttomText}`]: {
    color: grey[500],
    marginTop: '2rem'
  }
}));

class Reg extends React.Component {
  handleSubmit = values => {
    const {
      // username,
      userid,
      info: { basic = {} },
      dispatch
    } = this.props;
    const onlyWechat =
      basic &&
      basic.lastLoginType === 'wechat' &&
      /sso_\d+_\d+/.test(basic.username);

    return new Promise((resolve, reject) => {
      // 如果是从未授权初始页面跳转到reg页面，则成功后跳转回去。
      // 如果是以dialog方式跳出的reg组件，则返回当前页面
      // 如果微信授权登录后再注册，就是注册账号后绑定，将微信登录的用户id传入后台，2个账号合并
      dispatch({
        type: accountTypes.SAGA_REG_REQUEST,
        resolve: () => {
          resolve();
          if (onlyWechat) {
            // 绑定成功，提示一下
            dispatch(commonActions.showMessage('注册账号绑定微信成功', 'info'));
          }
        },
        reject,
        values,
        wxuserid: onlyWechat ? userid : null
      });
    });
  };

  render() {
    const {
      username,
      info: { basic = {} }
    } = this.props;
    // 最后一次是微信登录，且username是自定生成的格式，则当前账号只有微信登录信息，询问是否绑定用户名密码。
    // 如果没有账号则在当前注册登录并绑定当前已经登录的微信信息
    const onlyWechat =
      basic &&
      basic.lastLoginType === 'wechat' &&
      /sso_\d+_\d+/.test(basic.username);
    return (
      <Root>
        {username && !onlyWechat ? (
          <Redirect to="/sso/account/info" />
        ) : (
          <Card className={classes.card}>
            <CardContent>
              <Typography align="center" className={classes.title}>
                注册
                {onlyWechat ? '并绑定微信' : ''}
              </Typography>
              <RegForm onSubmit={this.handleSubmit} />
              <Typography align="right" className={classes.buttomText}>
                已有账户,
                <Link to="/sso/login">登录</Link>
              </Typography>
            </CardContent>
          </Card>
        )}
      </Root>
    );
  }
}

function mapStateToProps(state) {
  return {
    info: state.account.info,
    userid: state.account.id,
    username: state.account.username
  };
}

export default compose(connect(mapStateToProps))(Reg);
