import React from 'react';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { connect } from 'react-redux';
import RegForm from '../../forms/account/RegForm';
import { types as accountTypes } from '../../reducers/account';
import { Card, CardContent, Typography } from '@mui/material';
import grey from '@mui/material/colors/grey';
import compose from 'recompose/compose';
import history from '../../history';

const PREFIX = 'AuthReg';

const classes = {
  card: `${PREFIX}-card`,
  title: `${PREFIX}-title`
};

const Root = styled('div')(({ theme }) => ({
  height: '80%',
  display: 'flex',

  [`& .${classes.card}`]: {
    width: '30rem',
    margin: 'auto'
  },

  [`& .${classes.title}`]: theme.typography.title3
}));

class Reg extends React.Component {
  constructor(props) {
    super(props);
    /**
     * 原来的参数逻辑：
     * authOk：登录成功回调中间页
     * redirect：登录成功回调中间页处理完后实际跳转页面，用于在第三方页面任意页面登录状态没有后再次授权时回到原来页面
     * 目前暂时只用一个redirect中间页参数，具体再跳转的实际页面前端自行处理
     */
    const {
      from,
      token,
      redirect
      // , authOk
    } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    // if (!(from && token && redirect && authOk)) { // 登录前哪有token? => 绑定用户时有，不在这个页面
    if (!(from && redirect)) {
      history.push(
        `/sso/redirect?content=${encodeURIComponent(
          '授权认证信息不全'
        )}&to=${encodeURIComponent(redirect)}`
      );
    }
    this.state = {
      subTitle: `针对${from}进行授权`,
      token,
      redirect,
      from
      // authOk
    };
  }
  handleSubmit = values => {
    const {
      from,
      token,
      redirect
      // , authOk
    } = this.state;
    return new Promise((resolve, reject) => {
      this.props.dispatch({
        type: accountTypes.SAGA_REG_REQUEST,
        resolve,
        reject,
        values,
        from,
        redirect,
        token
      });
    });
  };

  render() {
    console.log('render reg');
    const { from, token = '', redirect } = this.state;
    return (
      <Root>
        {/* {username ? (
          <Redirect to="/" />
        ) : ( */}
        <Card className={classes.card}>
          <CardContent>
            <div className={classes.title}>
              <Typography align="center" variant="h5">
                注册并授权
              </Typography>
              <Typography align="center" color="textSecondary">
                {this.state.subTitle}
              </Typography>
            </div>
            <RegForm onSubmit={this.handleSubmit} />
            <Typography
              align="right"
              sx={{
                color: grey[500],
                mt: '2rem'
              }}
            >
              已有账户,
              <Link
                to={`/sso/auth/login?from=${from}&token=${token}&redirect=${encodeURIComponent(
                  redirect
                )}`}
              >
                登录
              </Link>
            </Typography>
          </CardContent>
        </Card>
        {/* )} */}
      </Root>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.account.username
  };
}

export default compose(connect(mapStateToProps))(Reg);
