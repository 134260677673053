import React from 'react';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import { actions as commonAction } from '../reducers/common';
import compose from 'recompose/compose';
import { Snackbar, SnackbarContent } from '@mui/material';
// import { theme } from '../services/themeProvider';

const PREFIX = 'Message';

const classes = {
  info: `${PREFIX}-info`,
  warn: `${PREFIX}-warn`,
  error: `${PREFIX}-error`
};

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  [`& .${classes.info}`]: {
    backgroundColor: theme.palette.info.main,
    // color: theme.palette.info.contrastText
    color: '#fff'
  },

  [`& .${classes.warn}`]: {
    backgroundColor: theme.palette.warn.main,
    // color: theme.palette.info.contrastText
    color: '#fff'
  },

  [`& .${classes.error}`]: {
    backgroundColor: theme.palette.error.main,
    // color: theme.palette.info.contrastText
    color: '#fff'
  }
}));

function mapStateToProps(state) {
  return {
    showMessage: state.common.showMessage,
    message: state.common.message,
    messageType: state.common.messageType
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeMessage: () => {
      dispatch(commonAction.closeMessage());
    }
  };
}
const Message = ({ showMessage, messageType, message, closeMessage }) => {
  return (
    <StyledSnackbar
      open={showMessage}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      autoHideDuration={3000}
      onClose={closeMessage}
    >
      <SnackbarContent
        className={classes[messageType]}
        message={<strong>{message}</strong>}
      />
    </StyledSnackbar>
  );
};
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Message);
