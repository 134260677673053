import React, { PureComponent, useState } from 'react';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import {
  // Typography,
  Box,
  Grid,
  Card,
  Input,
  CardContent,
  // CardActions,
  Button,
  Slider
} from '@mui/material';
// import { reduxForm } from 'redux-form';
// import { Link } from 'react-router-dom';
import {
  ArrowDownward as DownloadIcon,
  HighQuality as HighQualityIcon
} from '@mui/icons-material';
import compose from 'recompose/compose';
import { actions as toolsActions } from '../../reducers/tools';
// import { actions as commonActions } from '../../reducers/common';

// import { RenderInputFile } from '../../forms/renderFields';
// import { required } from '../../services/validate';

import { saveAs } from 'file-saver';
import JSZip from 'jszip';

// 带样式的CardContent组件
const PreviewCardContent = styled(CardContent)(() => ({
  fontSize: '1.4rem',
  color: '#555',
  margin: '30px auto 0',
  maxWidth: '600px',
  textAlign: 'right' // 按钮靠右
}));

// 带样式的文件上传容器
const FileContainer = styled('div')(() => ({
  border: '4px dashed rgba(209, 213, 219, 1)',
  borderRadius: '4px',
  background: 'rgba(243, 244, 246, 1)',
  height: '20rem',
  marginBottom: '20px',
  textAlign: 'center',
  position: 'relative',
  '&:hover, &.hover': {
    border: '4px dashed rgba(0, 0, 0, .5)',
    borderRadius: '4px',
    background: '#bdff01'
  }
}));

// 带样式的单个图片展示组件
const PreviewImageItem = styled('div')(() => ({
  position: 'relative',
  marginBottom: '10px',
  background: 'rgba(0, 0, 0, 0.05)',
  textAlign: 'left',
  padding: '5px'
}));

// 带样式的单个图片展示组件
const PreviewImageDownloadButton = styled(DownloadIcon)(() => ({
  position: 'absolute',
  right: '0',
  top: '0',
  cursor: 'pointer'
}));

// 带样式的image名字组件
const PreviewImageName = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  paddingBottom: '5px'
}));

// 带样式的预览image外框组件
const PreviewImageWrap = styled('div')(() => ({
  width: '60px',
  height: '60px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#fff'
}));

// 带样式的预览image组件
const PreviewImage = styled('img')(() => ({
  maxWidth: '60px',
  maxHeight: '60px'
}));

// 带样式的数字组件
const BoldNumber = styled('b')(() => ({
  marginRight: '30px'
}));

// 模拟ajax请求文件下载，防止直接打开图片
function fileAjax(url, callback, options) {
  const xhr = new XMLHttpRequest();
  xhr.open('get', url, true);
  if (options.responseType) {
    xhr.responseType = options.responseType;
  }
  xhr.onreadystatechange = function() {
    if (xhr.readyState === 4 && xhr.status === 200) {
      callback(xhr);
    }
  };
  xhr.send();
}

function downloadFile(content, filename) {
  window.URL = window.URL || window.webkitURL;
  const a = document.createElement('a');
  const blob = new Blob([content]);
  // 通过二进制文件创建url
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  // 销毁创建的url
  window.URL.revokeObjectURL(url);
}

class ImageMin extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      compressedImages: null,
      quality: 60
    };
  }

  // 质量改变
  handleQualityChange = (evt, value) => {
    this.setState({ quality: value });
  };

  // 质量input改变
  handleInputChange = evt => {
    this.setState({ quality: evt.target.value * 1 });
  };

  // 质量input失焦
  handleInputBlur = evt => {
    let inputValue = evt.target.value * 1;
    if (inputValue < 0) {
      inputValue = 0;
    } else if (inputValue > 100) {
      inputValue = 100;
    }
    this.setState({ quality: inputValue });
  };

  getFile = async function(entry) {
    return new Promise(resolve => {
      entry.file(file => resolve(file));
    });
  };

  // Drop handler function to get all files
  getAllFileEntries = async function(items) {
    const fileEntries = [];
    // Use BFS to traverse entire directory/file structure
    const queue = [];
    // Unfortunately items is not iterable i.e. no forEach
    for (let i = 0; i < items.length; i += 1) {
      queue.push(items[i].webkitGetAsEntry());
    }
    while (queue.length > 0) {
      const entry = queue.shift();
      if (entry && entry.isFile) {
        // Only append images
        const file = await this.getFile(entry);
        fileEntries.push(file);
      } else if (entry && entry.isDirectory) {
        queue.push(
          ...(await this.readAllDirectoryEntries(entry.createReader()))
        );
      }
    }
    return fileEntries;
  };

  // Get all the entries (files or sub-directories) in a directory
  // by calling readEntries until it returns empty array
  readAllDirectoryEntries = async function(directoryReader) {
    const entries = [];
    let readEntries = await this.readEntriesPromise(directoryReader);
    while (readEntries.length > 0) {
      entries.push(...readEntries);
      readEntries = await this.readEntriesPromise(directoryReader);
    }
    return entries;
  };

  // Wrap readEntries in a promise to make working with readEntries easier
  // readEntries will return only some of the entries in a directory
  // e.g. Chrome returns at most 100 entries at a time
  readEntriesPromise = async function(directoryReader) {
    return new Promise((resolve, reject) => {
      directoryReader.readEntries(resolve, reject);
    });
  };

  // 拖拽选中文件
  fileSelected = async (evt, fileInput) => {
    evt.preventDefault();
    const items = await this.getAllFileEntries(evt.dataTransfer.items);

    console.log('file :>> ', items);
    this.doFileUpload(items, fileInput);
  };

  // 执行文件上传
  doFileUpload = (files, fileInput) => {
    const { dispatch } = this.props;
    const { quality } = this.state;

    this.setState({
      compressedImages: null
    });

    dispatch(
      toolsActions.imageMin(
        compressedImages => {
          if (compressedImages && compressedImages.length > 0) {
            this.setState({ compressedImages });
          }
          // 清空file，可以再次上传同一个图片尝试不同质量的图
          if (fileInput.current) {
            fileInput.current.value = '';
          }
        },
        files,
        quality
      )
    );
  };

  // 文件下载
  imageDownload = (file, filename) => {
    // 发送http请求，将文件链接转换成文件流
    fileAjax(
      file,
      function(xhr) {
        downloadFile(xhr.response, filename);
      },
      {
        responseType: 'blob'
      }
    );
  };

  // 文件选中
  onFileChange = (evt, fileInput) => {
    this.doFileUpload(evt.target.files, fileInput);
  };

  /**
   * 多文件打包并下载
   */
  zipDownload = () => {
    const { compressedImages } = this.state;

    if (compressedImages.length === 1) {
      const [img] = compressedImages;
      return this.imageDownload(img.imageUrl, img.filename);
    }
    const zip = new JSZip();
    const folder = zip.folder('files');
    return Promise.resolve()
      .then(() => {
        return compressedImages.reduce((accumulator, file) => {
          return accumulator.then(() =>
            fetch(file.imageUrl)
              .then(resp => resp.blob())
              .then(blob => folder.file(file.filename, blob))
          );
        }, Promise.resolve());
      })
      .then(() => {
        folder
          .generateAsync({ type: 'blob' })
          .then(content => saveAs(content, '下载'));
      });
  };

  render() {
    const { compressedImages, quality } = this.state;
    return (
      <Card sx={{ mb: '50px' }}>
        <CardContent>
          <ImageMinForm
            onFileChange={this.onFileChange}
            handleDrop={this.fileSelected}
          />
          <Box sx={{ maxWidth: '500px', m: '0 auto' }}>
            <Grid container spacing={3} alignItems="center">
              <Grid item sx={{ display: 'flex' }}>
                <HighQualityIcon />
                压缩质量(%)
              </Grid>
              <Grid item xs>
                <Slider
                  value={typeof quality === 'number' ? quality : 0}
                  onChange={this.handleQualityChange}
                  aria-labelledby="input-slider"
                  max={100}
                  min={0}
                  step={5}
                  valueLabelDisplay="auto"
                />
              </Grid>
              <Grid item>
                <Input
                  value={quality}
                  size="small"
                  onChange={this.handleInputChange}
                  onBlur={this.handleInputBlur}
                  inputProps={{
                    step: 5,
                    min: 0,
                    max: 100,
                    type: 'number',
                    'aria-labelledby': 'input-slider'
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        {compressedImages &&
          compressedImages.length > 0 && (
            <PreviewCardContent>
              {compressedImages.map((img, index) => (
                <PreviewImageItem key={index}>
                  <PreviewImageDownloadButton
                    onClick={() =>
                      this.imageDownload(img.imageUrl, img.filename)
                    }
                  />
                  <PreviewImageName>
                    <PreviewImageWrap>
                      <PreviewImage src={img.imageUrl} alt="" />
                    </PreviewImageWrap>
                    {img.filename}
                  </PreviewImageName>
                  尺寸：
                  <BoldNumber>{img.size}</BoldNumber>
                  原始尺寸：
                  <BoldNumber>{img.originSize}</BoldNumber>
                  压缩比例：
                  <BoldNumber>{img.cutPercent}%</BoldNumber>
                </PreviewImageItem>
              ))}

              <Button size="small" onClick={this.zipDownload}>
                <DownloadIcon size="small" />
                全部下载
              </Button>
            </PreviewCardContent>
          )}
      </Card>
    );
  }
}

// const enchance = compose(
//   reduxForm({
//     form: 'imageMinForm'
//   })
// );

const transparentCoverBox = {
  width: '100%',
  height: '100%',
  opacity: '0',
  cursor: 'pointer'
};

// 带样式的文件上传input容器
const FileInputBox = styled('div')(() => transparentCoverBox);

// 带样式的文件上传input
const FileInput = styled('input')(() => transparentCoverBox);

const ImageMinTip = styled('div')(() => ({
  fontSize: '2rem',
  position: 'absolute',
  left: '0',
  right: '0',
  top: '8rem',
  zIndex: '0',
  pointerEvents: 'none'
}));

// 上传表单
// const ImageMinForm = enchance(
const ImageMinForm = ({ error, onFileChange, handleDrop }) => {
  const fileInput = React.useRef();
  const [dragHover, setDragHover] = useState(false);
  return (
    <FileContainer
      spacing={2}
      className={dragHover ? 'hover' : ''}
      onDrop={evt => {
        handleDrop(evt, fileInput);
        setDragHover(false);
      }}
      onDragOver={ev => {
        ev.stopPropagation();
        ev.preventDefault();
        setDragHover(true);
      }}
      onDragLeave={() => setDragHover(false)}
    >
      {error && (
        <div>
          <strong>{error}</strong>
        </div>
      )}
      <FileInputBox>
        {/* <Field
            name="file"
            label="点击上传"
            falseLabel="上传失败"
            component={RenderInputFile}
            onChange={onFileChange}
            validate={required}
          /> */}
        <FileInput
          ref={fileInput}
          id="contained-button-file"
          onChange={evt => onFileChange(evt, fileInput)}
          type="file"
          multiple={true}
          accept="image/*"
        />
      </FileInputBox>
      <ImageMinTip>点击或拖入图片</ImageMinTip>
    </FileContainer>
  );
};
// );

// function mapStateToProps(state) {
//   return {
//     username: state.account.username
//   };
// }

export default compose(
  // connect(mapStateToProps)
  connect()
)(ImageMin);
