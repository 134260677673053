import React, { PureComponent } from 'react';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import {
  Card,
  CardContent,
  Grid,
  // CardActions,
  Button
} from '@mui/material';
import { Field, reduxForm } from 'redux-form';
// import { Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { actions as toolsActions } from '../../reducers/tools';
import { actions as commonActions } from '../../reducers/common';

import { RenderTextField } from '../../forms/renderFields';
import { required } from '../../services/validate';
import { trim } from '../../services/normalize';

// 带样式的CardContent组件
const PreviewCardContent = styled(CardContent)(() => ({
  textAlign: 'center',
  fontSize: '1.4rem',
  color: '#555',
  marginTop: '30px'
}));

// 带样式的image容器组件
const PreviewImageWrap = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
  paddingBottom: '10px'
}));

// 带样式的预览image组件
const PreviewImage = styled('img')(() => ({
  maxWidth: '500px',
  maxHeight: '360px'
}));

class UploadCDN extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      cdnUrl: null,
      refreshResult: null
    };
  }

  onFileChange = () => {
    this.setState({
      cdnUrl: null,
      refreshResult: null
    });
  };

  // 重新渲染下图片
  getImage = () => {
    const { cdnUrl } = this.state;
    this.setState({
      cdnUrl: null
    });
    setTimeout(() => {
      this.setState({
        cdnUrl
      });
    }, 100);
  };

  handleSubmit = values => {
    const { dispatch } = this.props;
    const pattern = /^(http|https|ftp):\/\/[\w-]+(\.[\w-]+)*([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?$/;
    if (!values.cdnpath || !pattern.test(values.cdnpath)) {
      return dispatch(
        commonActions.showMessage('请输入正确的cdn地址', 'error')
      );
    }

    this.onFileChange();
    return new Promise(resolve => {
      dispatch(
        toolsActions.refreshCDN(res => {
          resolve();
          if (res.result && res.result.code === 200) {
            dispatch(commonActions.showMessage('刷新成功', 'info'));
            this.setState({
              refreshResult: res.result,
              cdnUrl: values.cdnpath
            });
          } else {
            this.setState({ refreshResult: null, cdnUrl: null });
          }
        }, values)
      );
    });
  };

  render() {
    const { refreshResult, cdnUrl } = this.state;
    // console.log('refreshResult :>> ', refreshResult, cdnUrl && checkImage(cdnUrl));

    return (
      <Card sx={{ mb: '50px' }}>
        <CardContent>
          <RefreshForm
            onSubmit={this.handleSubmit}
            onFileChange={this.onFileChange}
          />
        </CardContent>
        {cdnUrl && (
          <PreviewCardContent>
            {checkImage(cdnUrl) && (
              <PreviewImageWrap>
                <PreviewImage src={cdnUrl} alt="" />
                <Button onClick={this.getImage}>重新查看图片</Button>
              </PreviewImageWrap>
            )}
            <div>
              提示：如果文件没有立即更新，可稍等片刻再查看。不用多次点击"提交刷新请求"按钮以免浪费刷新次数。
            </div>
            <div>
              文件夹可用刷新总次数：
              {refreshResult.dirQuotaDay} 当前剩余：
              {refreshResult.dirSurplusDay}
            </div>
            <div>
              文件可用刷新总次数：
              {refreshResult.urlQuotaDay} 当前剩余：
              {refreshResult.urlSurplusDay}
            </div>
          </PreviewCardContent>
        )}
      </Card>
    );
  }
}

const enchance = compose(
  reduxForm({
    form: 'uploadCDNForm'
  })
);

// 上传表单
const RefreshForm = enchance(
  ({ pristine, submitting, error, handleSubmit, onFileChange }) => {
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {error && (
              <Grid item xs={12}>
                <strong>{error}</strong>
              </Grid>
            )}
            <Grid item xs={12} sm={12}>
              <Field
                name="cdnpath"
                component={RenderTextField}
                label="文件地址"
                normalize={trim}
                onChange={onFileChange}
                helperText="上传至CDN的文件地址，文件名结尾刷新文件，文件夹结尾刷新整个文件夹"
                validate={required}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <div style={{ paddingTop: '10px', textAlign: 'center' }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={pristine || submitting}
                >
                  提交刷新请求
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
);

// function mapStateToProps(state) {
//   return {
//     username: state.account.username
//   };
// }

function checkImage(filepath) {
  const extStart = filepath.lastIndexOf('.');
  const ext = filepath.substring(extStart, filepath.length).toUpperCase();
  if (['.BMP', '.PNG', '.GIF', '.JPG', '.JPEG'].indexOf(ext) === -1) {
    return false;
  }
  return true;
}

export default compose(
  // connect(mapStateToProps)
  connect()
)(UploadCDN);
