import React from 'react';
import { Button, Grid } from '@mui/material';
import { Field, reduxForm } from 'redux-form';
import compose from 'recompose/compose';
import {
  RenderTextField,
  RenderInputSelect,
  RenderDatePicker
} from '../renderFields';
import { required } from '../../services/validate';
import { trim } from '../../services/normalize';
import {
  formatSex,
  parseSex,
  formatStringToMoment,
  parseMomentToString
} from '../../services/formatParse';

const BasicInfoForm = ({
  pristine,
  submitting,
  error,
  reset,
  handleSubmit
}) => {
  console.log('basic info form render');
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {error && (
            <Grid item xs={12}>
              <strong>{error}</strong>
            </Grid>
          )}
          <Grid item xs={6} sm={2}>
            <Field
              name="name"
              component={RenderTextField}
              label="姓名"
              validate={required}
              normalize={trim}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <Field
              name="phone"
              label="电话"
              component={RenderTextField}
              normalize={trim}
              validate={required}
            />
          </Grid>
          <Grid item xs={6} sm={2}>
            <Field
              name="sex"
              label="性别"
              data={[
                { label: '男', value: 'male' },
                { label: '女', value: 'female' }
              ]}
              component={RenderInputSelect}
              format={formatSex}
              parse={parseSex}
            />
          </Grid>
          <Grid item xs={6} sm={2}>
            <Field
              name="birthday"
              label="出生日期"
              minDate="1900-01-01"
              maxDate={`${new Date().getFullYear() - 1}-12-31`}
              component={RenderDatePicker}
              format={formatStringToMoment}
              parse={parseMomentToString}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Field
              name="email"
              label="邮箱"
              component={RenderTextField}
              normalize={trim}
              validate={required}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Field
              name="birthPlace"
              label="籍贯"
              component={RenderTextField}
              normalize={trim}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Field
              name="idCard"
              label="身份证"
              component={RenderTextField}
              normalize={trim}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div style={{ paddingTop: '10px', textAlign: 'right' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={pristine || submitting}
              >
                保存
              </Button>
              <Button
                variant="text"
                onClick={reset}
                style={{ marginLeft: '10px' }}
                disabled={pristine || submitting}
              >
                取消
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const enchance = compose(
  reduxForm({
    form: 'basicInfoForm'
    // shouldAsyncValidate,
    // validate: syncCheckRegForm,
    // asyncValidate: asyncCheckUsername,
    // asyncBlurFields: ['username']
  })
);

export default enchance(BasicInfoForm);
