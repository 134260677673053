import Cookies from 'js-cookie';

export const types = {
  LOGIN_SUCCESS: 'ACCOUNT/LOGIN_SUCCESS',
  LOGOUT_SUCCESS: 'ACCOUNT/LOGOUT_SUCCESS',
  AUTH_INFO: 'ACCOUNT/AUTH_INFO',
  CLEAR_AUTH: 'ACCOUNT/CLEAR_AUTH',
  SET_USER_BASIC_INFO: 'ACCOUNT/SET_USER_BASIC_INFO',
  SET_USER_BINDS: 'ACCOUNT/SET_USER_BINDS',
  UPDATE_USER_BASIC_INFO: 'ACCOUNT/UPDATE_USER_BASIC_INFO',
  SAGA_CHECK_USER_AUTH: 'ACCOUNT/SAGA_CHECK_USER_AUTH',
  SAGA_GET_USER_BASIC_INFO: 'ACCOUNT/SAGA_GET_USER_BASIC_INFO', // 获取用户基本信息
  SAGA_SET_USER_BASIC_INFO: 'ACCOUNT/SAGA_SET_USER_BASIC_INFO', // basicinfo 页面submit
  SAGA_GET_BINDS_REQUEST: 'ACCOUNT/SAGA_GET_BINDS_REQUEST',
  SAGA_TOGGLE_BIND_REQUEST: 'ACCOUNT/SAGA_TOGGLE_BIND_REQUEST',
  SAGA_REG_REQUEST: 'ACCOUNT/SAGA_REG_REQUEST',
  SAGA_LOGIN_REQUEST: 'ACCOUNT/SAGA_LOGIN_REQUEST',
  SAGA_WECHAT_LOGIN_REQUEST: 'ACCOUNT/SAGA_WECHAT_LOGIN_REQUEST', // 微信授权登录
  SAGA_BIND_WECHAT_REQUEST: 'ACCOUNT/SAGA_BIND_WECHAT_REQUEST', // 绑定微信
  SAGA_LOGOUT_REQUEST: 'ACCOUNT/SAGA_LOGOUT_REQUEST',
  SAGA_CHANGE_PASSORD_REQUEST: 'ACCOUNT/SAGA_CHANGE_PASSORD_REQUEST',
  SAGA_FORCE_LOGOUT: 'ACCOUNT/SAGA_FORCE_LOGOUT',
  SAGA_CHECK_USERNAME: 'ACCOUNT/SAGA_CHECK_USERNAME'
};

export const actions = {
  userAuth: (username, active, id = '', role) => ({
    type: types.AUTH_INFO,
    id,
    username,
    active,
    role
  }),
  clearAuth: () => ({
    type: types.CLEAR_AUTH
  }),
  userLogin: (resolve, values, from, wxuserid) => ({
    type: types.SAGA_LOGIN_REQUEST,
    resolve,
    values,
    from,
    wxuserid
  }),
  loginSuccess: (id, username, active, role) =>
    actions.userAuth(username, active, id, role),
  logoutSuccess: () => ({ type: types.LOGOUT_SUCCESS }),
  getBasicInfo: resolve => ({ type: types.SAGA_GET_USER_BASIC_INFO, resolve }),
  setBasicInfo: data => ({ type: types.SET_USER_BASIC_INFO, data }),
  setBasicInfoRequest: (resolve, values, redirect) => ({
    type: types.SAGA_SET_USER_BASIC_INFO,
    resolve,
    values,
    redirect
  }),
  /**
   * 在用户刷新网页，第一次加载时，如果本地cookie有用户信息，则增加一次远程验证
   */
  checkUserAuth: () => ({ type: types.SAGA_CHECK_USER_AUTH }),
  setUserBinds: data => ({
    type: types.SET_USER_BINDS,
    binds: data.userBinds,
    sites: data.ssoSite
  })
};

const initState = {
  id: '',
  username: '',
  active: 2, // 账户状态: 0未激活，1正常,2禁用
  role: null,
  isSuperAdmin: false,
  info: {}, // {basic:{}}
  binds: null, // 第三方系统的绑定关系
  sites: null // 第三方系统列表
  // workExp: null, // 工作经历
  // eduExp: null // 教育经历
};

export default function accountReducer(state = initState, action) {
  switch (action.type) {
    case types.AUTH_INFO:
      return {
        ...state,
        id: action.id,
        username: action.username,
        active: action.active,
        role: action.role
      };
    case types.CLEAR_AUTH:
      Cookies.remove('username');
      Cookies.remove('active');
      return {
        ...state,
        ...initState
      };
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        ...initState
      };
    case types.SET_USER_BASIC_INFO:
      return {
        ...state,
        active: 0,
        info: {
          ...state.info,
          basic: action.data
        }
      };
    case types.UPDATE_USER_BASIC_INFO:
      return {
        ...state,
        info: {
          ...state.info,
          basic: action.data
        }
      };
    case types.SET_USER_BINDS:
      return {
        ...state,
        binds: action.binds,
        sites: action.sites
      };
    default:
      return state;
  }
}
