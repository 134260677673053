import { fork, take, put, call } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import { md5Passwd } from '../services/utility';
import { types as accountTypes } from '../reducers/account';
import { actions as accountActions } from '../reducers/account';
// import { types as commonTypes } from '../reducers/common';
import axios from 'axios';
// import Cookies from 'js-cookie';
import history from '../history';

/**
 * 处理用户注册页面的submit异步请求
 */
function* regFlow() {
  while (true) {
    const {
      resolve,
      reject,
      values,
      from,
      redirect,
      token,
      wxuserid
    } = yield take(accountTypes.SAGA_REG_REQUEST);
    let { username, password1: password } = values;
    password = md5Passwd(password);
    const response = yield axios.post('/sso/account/reg', {
      username,
      password,
      wxuserid
    });
    if (response.code === 200) {
      yield call(resolve);
      // 注册后直接登录，保存登录信息
      yield put(
        accountActions.loginSuccess(
          response.data.id,
          username,
          response.data.active,
          response.data.role
        )
      );
      // yield call(history.push, '/login');
      if (from && redirect) {
        // 有from，redirect说明时授权注册，跳授权登录
        yield call(
          history.push,
          `/sso/auth/login?from=${from}&token=${token}&redirect=${encodeURIComponent(
            redirect
          )}`
        );
      } else if (wxuserid) {
        // 绑定微信，则重新获取个人信息并跳个人信息页
        const response = yield axios.get('/sso/account/info/basic');
        if (response.code === 200) {
          yield put(accountActions.setBasicInfo(response.data));
        }
        yield call(history.push, '/sso/account/info');
      } else {
        // 否则为直接注册，跳直接登录页面
        yield call(history.push, '/sso/login');
      }
    } else {
      yield put(stopSubmit('regForm', { _error: response.error }));
      yield call(reject);
    }
  }
}

function* loginFlow() {
  while (true) {
    const { resolve, values, from, wxuserid } = yield take(
      accountTypes.SAGA_LOGIN_REQUEST
    );
    const username = values.username.toLowerCase();
    const password = md5Passwd(values.password);
    const remember = !!values.remember;
    const response = yield axios.post('/sso/account/login', {
      username,
      password,
      remember,
      wxuserid
    });
    if (response.code === 200) {
      yield call(resolve);
      yield put(
        accountActions.loginSuccess(
          response.data.id,
          username,
          response.data.active,
          response.data.role
        )
      );
      if (from.pathname) {
        yield call(history.push, from.pathname); // 根据url的redirect进行跳转
      } else {
        if (wxuserid) {
          // 绑定微信，则重新获取个人信息并跳个人信息页
          const response = yield axios.get('/sso/account/info/basic');
          if (response.code === 200) {
            yield put(accountActions.setBasicInfo(response.data));
          }
        }
        yield call(history.push, '/sso/account/info'); // 没有redirect到个人信息页面
      }
    } else {
      yield put(stopSubmit('loginForm', { _error: response.error }));
    }
  }
}

// 微信登录
function* wechatLoginFlow() {
  while (true) {
    const { code, from, redirect, resolve, remember } = yield take(
      accountTypes.SAGA_WECHAT_LOGIN_REQUEST
    );
    // 通过code微信登录，有账号获取账号信息，没有账号新建账号绑定微信信息，获取账号信息
    const response = yield axios.post('/sso/account/wxlogin', {
      code,
      remember,
      from,
      redirect
    });
    if (response.code === 200) {
      if (resolve) {
        yield call(resolve);
      }
      yield put(
        accountActions.loginSuccess(
          response.data.id,
          response.data.username,
          response.data.active,
          response.data.role
        )
      );
      if (redirect) {
        // 根据url的redirect进行跳转
        const location = `${redirect}?jwt=${response.data.token}`;
        global.location = location;
      } else {
        yield call(history.push, '/sso/account/info'); // 没有redirect到个人信息页面
      }
    } else {
      yield put(stopSubmit('loginForm', { _error: response.error }));
    }
  }
}

function* logoutFlow() {
  while (true) {
    const { type, toLogin } = yield take([
      accountTypes.SAGA_LOGOUT_REQUEST,
      accountTypes.SAGA_FORCE_LOGOUT
    ]);
    if (type === accountTypes.SAGA_LOGOUT_REQUEST) {
      yield axios.post('/sso/account/logout');
    }
    yield put(accountActions.clearAuth());
    if (toLogin) {
      // sso系统右上角注销，停在sso系统跳到登录页面；如果第三方系统在登出页面登出会跳回第三方系统
      yield call(history.push, '/sso/login'); // 登出之后跳到登录页面
    }
  }
}

/**
 * 处理用户修改密码页面的submit异步请求
 */
function* changePassordFlow() {
  while (true) {
    const { resolve, reject, values, redirect } = yield take(
      accountTypes.SAGA_CHANGE_PASSORD_REQUEST
    );
    let { username, password, password2 } = values;
    password = md5Passwd(password);
    password2 = md5Passwd(password2);
    const response = yield axios.post('/sso/account/changePassword', {
      username,
      password,
      password2
    });
    if (response.code === 200) {
      yield call(resolve);
      if (redirect) {
        // 有redirect说明是第三方系统跳转过来的，跳回去
        global.location = redirect;
      } else {
        yield call(history.push, '/sso/');
      }
    } else {
      yield put(stopSubmit('passwordForm', { _error: response.error }));
      yield call(reject);
    }
  }
}

/**
 * 处理用户注册页面username焦点消失时的唯一性验证
 */
function* checkUsernameFlow() {
  while (true) {
    const { values, resolve, reject } = yield take(
      accountTypes.SAGA_CHECK_USERNAME
    );
    const response = yield axios.get(`/sso/account/check/${values.username}`);
    if (response.code === 200) {
      if (response.data.isNameExist) {
        yield call(reject, { username: `用户名${values.username}已存在` });
      } else {
        yield call(resolve);
      }
    } else {
      yield call(reject, { username: ' ' }); // 空格用于保持textField的error提示行高度
    }
  }
}

/**
 * 在初始打开网页的时候到服务器获取用户认证相关信息
 * cookie中本地无法获取id，在此获取用户的id
 */
function* checkUserAuthFlow() {
  // while (true) {
  yield take(accountTypes.SAGA_CHECK_USER_AUTH);
  const res = yield axios.post('/sso/account/auth');
  if (res.code === 200) {
    const {
      id,
      username,
      active,
      authInfo: { role }
    } = res.data;
    yield put(accountActions.loginSuccess(id, username, active, role));
  }
  // }
}

// 获取用户信息
function* getBasicInfoFlow() {
  while (true) {
    const { resolve } = yield take(accountTypes.SAGA_GET_USER_BASIC_INFO);
    const response = yield axios.get('/sso/account/info/basic');
    if (response.code === 200) {
      yield put(accountActions.setBasicInfo(response.data));
    }
    if (resolve) {
      yield call(resolve);
    }
  }
}

// 保存用户信息
function* setBasicInfoFlow() {
  while (true) {
    const { resolve, values, redirect } = yield take(
      accountTypes.SAGA_SET_USER_BASIC_INFO
    );
    const response = yield axios.post('/sso/account/info/basic', values);
    if (response.code === 200) {
      yield put(accountActions.setBasicInfo(values));
      yield call(resolve);
      if (redirect) {
        // 有redirect说明是第三方系统跳转过来的，跳回去
        global.location = redirect;
      }
    } else {
      yield put(stopSubmit('basicInfoForm', { _error: response.error }));
    }
  }
}

// 用户绑定微信
function* bindWechatFlow() {
  while (true) {
    const { resolve, code, userid } = yield take(
      accountTypes.SAGA_BIND_WECHAT_REQUEST
    );
    const response = yield axios.post('/sso/account/bindwechat', {
      code,
      userid
    });
    if (response.code === 200) {
      yield put(accountActions.setBasicInfo(response.data));
      if (resolve) {
        yield call(resolve);
      }
    }
  }
}

export default [
  fork(checkUsernameFlow),
  fork(regFlow),
  fork(checkUserAuthFlow),
  fork(loginFlow),
  fork(wechatLoginFlow),
  fork(logoutFlow),
  fork(changePassordFlow),
  fork(getBasicInfoFlow),
  fork(setBasicInfoFlow),
  fork(bindWechatFlow)
];
