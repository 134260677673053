import React, { PureComponent } from 'react';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import {
  Card,
  CardContent,
  Grid,
  // CardActions,
  Button
} from '@mui/material';
import { Field, reduxForm } from 'redux-form';
// import { Link } from 'react-router-dom';
import { FileCopyTwoTone as CopyIcon } from '@mui/icons-material';
import compose from 'recompose/compose';
import { actions as toolsActions } from '../../reducers/tools';
import { actions as commonActions } from '../../reducers/common';

import { RenderTextField, RenderInputFile } from '../../forms/renderFields';
import { required } from '../../services/validate';
import { trim } from '../../services/normalize';

// 带样式的CardContent组件
const PreviewCardContent = styled(CardContent)(() => ({
  textAlign: 'center',
  fontSize: '1.4rem',
  color: '#555',
  marginTop: '30px'
}));

// 带样式的预览image组件
const PreviewImage = styled('img')(() => ({
  maxWidth: '500px',
  maxHeight: '360px'
}));

class UploadCDN extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      cdnUrl: null
    };
  }

  copyCdnUrl = () => {
    const { cdnUrl } = this.state;
    const { dispatch } = this.props;
    const inputElement = global.document.createElement('input');
    inputElement.setAttribute('id', 'cp_cdn_url_input');
    inputElement.value = cdnUrl;
    global.document.getElementsByTagName('body')[0].appendChild(inputElement);
    global.document.getElementById('cp_cdn_url_input').select();
    global.document.execCommand('copy');
    global.document.getElementById('cp_cdn_url_input').remove();

    dispatch(commonActions.showMessage('复制地址成功，可粘贴使用', 'info'));
  };
  onFileChange = () => {
    this.setState({
      cdnUrl: null
    });
  };

  handleSubmit = values => {
    const { dispatch } = this.props;
    if (!values.file || !values.file[0]) {
      return dispatch(commonActions.showMessage('请先选择文件', 'error'));
    }

    const uploadValues = Object.assign({}, values); // assign一下，否则文件名清空了
    const [firstFile] = uploadValues.file; // 上传时取第一个文件
    uploadValues.file = firstFile;

    return new Promise(resolve => {
      dispatch(
        toolsActions.uploadCDN(res => {
          resolve();
          if (res.url) {
            this.setState({ cdnUrl: res.url });
          }
        }, uploadValues)
      );
    });
  };

  render() {
    const { cdnUrl } = this.state;
    // console.log('cdnUrl :>> ', cdnUrl, cdnUrl && checkImage(cdnUrl));
    // sx属性: https://mui.com/system/getting-started/the-sx-prop/
    return (
      <Card sx={{ mb: '50px' }}>
        <CardContent>
          <UploadForm
            onSubmit={this.handleSubmit}
            onFileChange={this.onFileChange}
          />
        </CardContent>
        {cdnUrl && (
          <PreviewCardContent>
            {checkImage(cdnUrl) && <PreviewImage src={cdnUrl} alt="" />}
            <Grid
              container
              sx={{
                justifyContent: 'space-evenly',
                flexDirection: 'column',
                gap: '5px'
              }}
            >
              <Grid item>
                CDN文件地址：
                {cdnUrl}
              </Grid>
              <Grid item>
                <Button size="small" onClick={this.copyCdnUrl}>
                  <CopyIcon size="small" />
                  点击复制地址
                </Button>
              </Grid>
            </Grid>
          </PreviewCardContent>
        )}
      </Card>
    );
  }
}

const enchance = compose(
  reduxForm({
    form: 'uploadCDNForm'
  })
);

// 上传表单
const UploadForm = enchance(
  ({ pristine, submitting, error, handleSubmit, onFileChange }) => {
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {error && (
              <Grid item xs={12}>
                <strong>{error}</strong>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <Field
                name="uppath"
                component={RenderTextField}
                label="文件路径(可选)"
                normalize={trim}
                helperText="配置上传至CDN的文件路径，默认按后台配置，例：nx-i"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="upname"
                label="文件名称(可选)"
                component={RenderTextField}
                normalize={trim}
                helperText="配置上传至CDN的文件名称，默认是crypto模块的randomUUID方法生成uuid加原文件后缀名"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Field
                name="file"
                label="点击上传"
                falseLabel=""
                component={RenderInputFile}
                onChange={onFileChange}
                validate={required}
              />
            </Grid>
            <Grid item xs={12} sm={12} sx={{ pt: '10px', textAlign: 'center' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={pristine || submitting}
              >
                上传
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
);

// function mapStateToProps(state) {
//   return {
//     username: state.account.username
//   };
// }

function checkImage(filepath) {
  const extStart = filepath.lastIndexOf('.');
  const ext = filepath.substring(extStart, filepath.length).toUpperCase();
  if (['.BMP', '.PNG', '.GIF', '.JPG', '.JPEG'].indexOf(ext) === -1) {
    return false;
  }
  return true;
}

export default compose(
  // connect(mapStateToProps)
  connect()
)(UploadCDN);
