import React from 'react';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import LoginForm from '../../forms/account/LoginForm';
import { Card, CardContent, Typography } from '@mui/material';
import grey from '@mui/material/colors/grey';
import compose from 'recompose/compose';
import { actions as accountActions } from '../../reducers/account';
import { actions as commonActions } from '../../reducers/common';

const Root = styled('div')(() => ({
  height: '80%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

const StyledTitle3 = styled(Typography)(({ theme }) => theme.typography.title3);

class Login extends React.Component {
  handleSubmit = values => {
    const { state } = this.props.location;
    const from = state ? state.from : '/';

    const {
      // username,
      userid,
      info: { basic = {} },
      dispatch
    } = this.props;
    const onlyWechat =
      basic &&
      basic.lastLoginType === 'wechat' &&
      /sso_\d+_\d+/.test(basic.username);

    return new Promise(resolve => {
      // 如果是从未授权初始页面跳转到login页面，则成功后跳转回去。
      // 如果是以dialog方式跳出的login组件，则返回当前页面
      // 如果微信授权登录后再登录，就是账号绑定，将微信登录的用户id传入后台，2个账号合并
      dispatch(
        accountActions.userLogin(
          () => {
            resolve();
            if (onlyWechat) {
              // 绑定成功，提示一下
              dispatch(
                commonActions.showMessage('登录账号绑定微信成功', 'info')
              );
            }
          },
          values,
          from,
          onlyWechat ? userid : null
        )
      );
    });
  };
  render() {
    const {
      username,
      info: { basic = {} }
    } = this.props;
    // 最后一次是微信登录，且username是自定生成的格式，则当前账号只有微信登录信息，询问是否绑定用户名密码。
    // 如果有账号则直接在当前页面登录绑定当前已经登录的微信信息
    const onlyWechat =
      basic &&
      basic.lastLoginType === 'wechat' &&
      /sso_\d+_\d+/.test(basic.username);

    return (
      <Root>
        {username && !onlyWechat ? (
          <Redirect to={{ pathname: '/sso/account/info' }} />
        ) : (
          <Card sx={{ width: '30rem' }}>
            <CardContent>
              <StyledTitle3 align="center">
                登录
                {onlyWechat ? '并绑定微信' : ''}
              </StyledTitle3>
              <LoginForm
                form="loginForm"
                bindWechat={onlyWechat}
                initialValues={{ username: '', remember: true }}
                onSubmit={this.handleSubmit}
              />
              <Typography
                align="right"
                sx={{
                  color: grey[500],
                  mt: '2rem'
                }}
              >
                没有账户,
                <Link to="/sso/reg">注册</Link>
              </Typography>
            </CardContent>
          </Card>
        )}
      </Root>
    );
  }
}

function mapStateToProps(state) {
  return {
    info: state.account.info,
    userid: state.account.id,
    username: state.account.username
  };
}
export default compose(connect(mapStateToProps))(Login);
