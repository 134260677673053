import React from 'react';

import { FormControlLabel, Button } from '@mui/material';
import { CloudUploadOutlined as CloudUploadIcon } from '@mui/icons-material';

const RenderInputFileField = ({
  label,
  falseLabel,
  labelPlacement = 'end',
  input: { value, onChange, ...inputRest },
  meta,
  ...rest
}) => {
  const fileName = value && value[0] ? value[0].name : null;
  console.log('value :>> ', value, fileName);
  return (
    <FormControlLabel
      control={
        <div>
          <input
            accept="*/*"
            id="contained-button-file"
            onChange={onChange}
            type="file"
            input={inputRest}
            meta={meta}
            style={{ display: 'none' }}
            {...rest}
          />
          <Button
            color="primary"
            variant="contained"
            component="span"
            size="small"
            style={{ margin: '0 1rem 0 14px' }}
          >
            <CloudUploadIcon style={{ marginRight: '1rem' }} />
            选择文件
          </Button>
        </div>
      }
      label={fileName ? fileName : falseLabel || label}
      labelPlacement={labelPlacement}
    />
  );
};

export default RenderInputFileField;
