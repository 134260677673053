import * as React from 'react';
import { Popper, Grow, Paper, ClickAwayListener } from '@mui/material';

// 逆行科技定制组件-Popper，一堆复杂配置提取出来
export default function NxPopper(props) {
  const { anchorEl, children, handleClose, ...rest } = props;

  return (
    <Popper
      anchorEl={anchorEl}
      role={undefined}
      placement="bottom-start"
      transition
      disablePortal
      sx={{ zIndex: 10000 }}
      {...rest}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom-start' ? 'left top' : 'left bottom'
          }}
        >
          <Paper elevation={3}>
            <ClickAwayListener onClickAway={handleClose}>
              {children}
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
