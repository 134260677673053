import React from 'react';
import { styled } from '@mui/material/styles';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PasswordForm from '../../forms/account/PasswordForm';
import { types as accountTypes } from '../../reducers/account';
import { Card, CardContent, Typography } from '@mui/material';
import compose from 'recompose/compose';
import qs from 'qs';

const PREFIX = 'Password';

const classes = {
  card: `${PREFIX}-card`,
  title: `${PREFIX}-title`
};

const Root = styled('div')(({ theme }) => ({
  height: '90%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  [`& .${classes.card}`]: {
    width: '30rem',
    margin: 'auto'
  },

  [`& .${classes.title}`]: { ...theme.typography.title3 }
}));

class ChangePasswd extends React.Component {
  constructor(props) {
    super(props);
    const { redirect } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });

    this.state = {
      redirect
    };
  }
  handleSubmit = values => {
    const { redirect } = this.state;
    return new Promise((resolve, reject) => {
      this.props.dispatch({
        type: accountTypes.SAGA_CHANGE_PASSORD_REQUEST,
        resolve,
        reject,
        values,
        redirect
      });
    });
  };

  render() {
    const { username } = this.props;
    console.log('Password username :>> ', username);
    return (
      <Root>
        {!username ? (
          <Redirect to="/sso/login" />
        ) : (
          <Card className={classes.card}>
            <CardContent>
              <Typography align="center" className={classes.title}>
                修改密码
              </Typography>
              <PasswordForm
                onSubmit={this.handleSubmit}
                initialValues={{ username }}
              />
            </CardContent>
          </Card>
        )}
      </Root>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.account.username
  };
}

export default compose(connect(mapStateToProps))(ChangePasswd);
