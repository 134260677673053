import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import history from '../../history';
import qs from 'qs';
import compose from 'recompose/compose';

const linkStyle = ({ theme }) => ({
  ...theme.sharedClass.link,
  marginLeft: '1rem'
});
const StyledTypographyLink = styled(Typography)(linkStyle);
const StyledLink = styled(Link)(linkStyle);

class CountDownRedirect extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      content: '',
      to: '/sso'
    };
  }
  componentDidMount() {
    const { content, redirect = '/sso', count = 5 } = qs.parse(
      this.props.location.search,
      {
        ignoreQueryPrefix: true
      }
    );
    this.setState({ content, to: redirect, countDown: count });
    this.timer = setInterval(this.count, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }
  count = () => {
    this.setState({ countDown: this.state.countDown - 1 });
  };
  redirect = () => {
    const { to } = this.state;
    if (to.startsWith('http://') || to.startsWith('https://')) {
      global.location = to;
    } else history.push(to);
  };

  render() {
    const { countDown, content } = this.state;
    if (countDown === 0) {
      clearInterval(this.timer);
      this.redirect();
    }
    return (
      <Grid
        sx={{ height: '50%' }}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={4} />
        <Grid item xs={4} container justifyContent="center" spacing={24}>
          <Grid item xs={2}>
            {countDown}
          </Grid>
          <Grid item xs container direction="column">
            <Grid item>{content}</Grid>
            <Grid item container>
              <Grid item>
                <Typography>正在为您跳转到目标页,</Typography>
              </Grid>
              <Grid item>
                <StyledTypographyLink onClick={this.redirect}>
                  直接跳转
                </StyledTypographyLink>
              </Grid>
              <Grid item>
                <StyledLink to="/sso">首页</StyledLink>
              </Grid>
              <Grid item>
                <StyledLink to="/sso/reg">注册</StyledLink>
              </Grid>
              <Grid item>
                <StyledLink to="/sso/login">登录</StyledLink>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} />
      </Grid>
    );
  }
}

export default compose()(CountDownRedirect);
