// import Cookies from 'js-cookie';

export const types = {
  SAGA_AUTH_LOGIN_REQUEST: 'SSO/SAGA_AUTH_LOGIN_REQUEST',
  SAGA_AUTH_USE_CURRENT_USER_REQUEST: 'SSO/SAGA_AUTH_USE_CURRENT_USER_REQUEST',
  SAGA_AUTH_BIND_REQUEST: 'SSO/SAGA_AUTH_BIND_REQUEST'
};

export const actions = {
  saga_auth_login_request: (
    resolve,
    values,
    from,
    redirect,
    token,
    authOk
  ) => ({
    type: types.SAGA_AUTH_LOGIN_REQUEST,
    resolve,
    values,
    from,
    redirect,
    token,
    authOk
  }),
  saga_auth_use_current_user_request: (
    resolve,
    from,
    redirect,
    token,
    authOk
  ) => ({
    type: types.SAGA_AUTH_USE_CURRENT_USER_REQUEST,
    resolve,
    from,
    redirect,
    token,
    authOk
  }),
  saga_auth_bind_request: (resolve, values, from, redirect, token) => ({
    type: types.SAGA_AUTH_BIND_REQUEST,
    resolve,
    values,
    from,
    redirect,
    token
  })
};

const initState = {};

export default function accountReducer(state = initState, action) {
  switch (action.type) {
    // case types.AUTH_INFO:
    //   return {
    //     ...state,
    //     id: action.id,
    //     username: action.username,
    //     active: action.active
    //   };
    default:
      return state;
  }
}
