const config = {};

export const pathTitle = {
  '/': '逆行科技',
  '/sso': '首页',

  '/sso/account/info': '个人信息',
  '/sso/account/password': '修改密码',

  '/sso/auth/login': '登录授权',
  '/sso/auth/reg': '注册授权',
  '/sso/auth/bind': '用户绑定',
  '/sso/auth/mybinds': '用户绑定管理',
  '/sso/auth/logout': '用户注销',
  '/sso/login': '用户登录',
  '/sso/reg': '用户注册',

  // tools
  '/tools/cdn': 'CDN上传',
  '/tools/cdnRefresh': 'CDN刷新',
  '/tools/imagemin': '图片压缩',

  '/sso/about': '关于' // TODO
};

export const sysName = '统一认证系统';

export default config;
