import React, { PureComponent } from 'react';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
// import PropTypes from 'prop-types';
import { types as accountTypes } from '../../reducers/account';
import {
  Card,
  CardContent,
  Button,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody
} from '@mui/material';
import { Error as ErrorIcon } from '@mui/icons-material';

const PREFIX = 'AuthBind';

const classes = {
  btnRevoke: `${PREFIX}-btnRevoke`,
  btnAgree: `${PREFIX}-btnAgree`,
  appTable: `${PREFIX}-appTable`,
  emptyBinds: `${PREFIX}-emptyBinds`,
  emptyIcon: `${PREFIX}-emptyIcon`,
  tipText: `${PREFIX}-tipText`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.btnRevoke}`]: { ...theme.sharedClass.errorBtn },

  [`& .${classes.btnAgree}`]: {
    ...theme.sharedClass.infoBtn
  },

  [`& .${classes.appTable}`]: {
    width: '100%',
    margin: '0 auto',
    paddingTop: '50px',
    maxWidth: '1200px'
  },

  [`& .${classes.emptyBinds}`]: {
    padding: '100px 20px 20px',
    fontSize: '1.8rem',
    color: '#555',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '.5rem'
  },

  [`& .${classes.emptyIcon}`]: {
    color: '#900',
    fontSize: '5rem',
    marginBottom: '20px'
  },

  [`& .${classes.tipText}`]: {
    fontSize: '1.4rem',
    color: '#555',
    marginTop: '30px'
  }
}));

class AuthBind extends PureComponent {
  componentDidMount() {
    if (!this.props.binds) {
      this.props.getBinds();
    }
  }

  toggleBind = (id, agreed) => {
    this.props.toggleBind(id, agreed);
  };

  render() {
    const { binds } = this.props;
    return (
      <Root>
        {binds && binds.length > 0 ? (
          <div className={classes.appTable}>
            <Card>
              <CardContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>序号</TableCell>
                      <TableCell>系统名称</TableCell>
                      <TableCell>系统代号</TableCell>
                      <TableCell>第三方系统用户名</TableCell>
                      <TableCell style={{ width: '15rem' }}>操作</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {binds.map((bind, index) => (
                      <TableRow key={bind.id}>
                        <TableCell scope="row">{index}</TableCell>
                        <TableCell>{bind['sso_site.name']}</TableCell>
                        <TableCell>{bind.ssoSymbol}</TableCell>
                        <TableCell>{bind.ssoUsername}</TableCell>
                        <TableCell>
                          <Button
                            className={
                              !bind.agreed
                                ? classes.btnAgree
                                : classes.btnRevoke
                            }
                            variant="contained"
                            size="small"
                            onClick={() => {
                              bind.disabled = true;
                              this.toggleBind(bind.id, bind.agreed);
                            }}
                          >
                            {bind.agreed ? '撤销' : '同意'}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
            <div className={classes.tipText}>
              *
              只通过sso登录的系统无需绑定操作。只有第三方系统自带账户体系才需要绑定，且绑定操作需要第三方系统在其系统登录之后发起。
            </div>
          </div>
        ) : (
          <div className={classes.emptyBinds}>
            <ErrorIcon className={classes.emptyIcon} />
            <div>暂未绑定第三方系统</div>
            <div className={classes.tipText}>
              *
              只通过sso登录的系统无需绑定操作。只有第三方系统自带账户体系才需要绑定，且绑定操作需要第三方系统在其系统登录之后发起。
            </div>
          </div>
        )}
      </Root>
    );
  }
}

function mapStateToProps(state) {
  return { binds: state.account.binds };
}

function mapDispatchToprops(dispatch) {
  return {
    getBinds: () => {
      dispatch({ type: accountTypes.SAGA_GET_BINDS_REQUEST });
    },
    toggleBind: (id, agreed) => {
      dispatch({ type: accountTypes.SAGA_TOGGLE_BIND_REQUEST, id, agreed });
    }
  };
}

AuthBind.propTypes = {};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToprops
  )
)(AuthBind);
