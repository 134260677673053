import React from 'react';
import { styled } from '@mui/material/styles';
import { Field, reduxForm } from 'redux-form';
import compose from 'recompose/compose';
import { required } from '../../services/validate';
import { trim } from '../../services/normalize';
import { Button } from '@mui/material';
import { RenderTextField, RenderSwitch } from '../../forms/renderFields';
import grey from '@mui/material/colors/grey';
import qs from 'qs';
import { types as accountTypes } from '../../reducers/account';
import WeixinLoginDialog from '../../components/weixinLoginDialog';

const PREFIX = 'LoginForm';

const classes = {
  errortext: `${PREFIX}-errortext`,
  buttonLine: `${PREFIX}-buttonLine`,
  button: `${PREFIX}-button`,
  buttomText: `${PREFIX}-buttomText`
};

const Root = styled('form')(() => ({
  [`& .${classes.errortext}`]: {
    color: '#e00',
    margin: '.5rem 0'
  },

  [`& .${classes.buttonLine}`]: {
    marginTop: '2rem',
    display: 'flex',
    justifyContent: 'space-around'
  },

  [`& .${classes.button}`]: {
    width: '100%'
  },

  [`& .${classes.buttomText}`]: {
    color: grey[500],
    marginTop: '1rem'
  }
}));

const LoginForm = props => {
  const {
    pristine,
    submitting,
    error,
    handleSubmit,
    dispatch,
    bindWechat
  } = props;
  const { code, from, redirect, token, remember } = qs.parse(
    global.location.search,
    {
      ignoreQueryPrefix: true
    }
  );
  console.log('code :>> ', code);

  if (code) {
    // 如果有code，说明是微信登录跳回来的，则获取微信信息并登录
    dispatch({
      type: accountTypes.SAGA_WECHAT_LOGIN_REQUEST,
      code,
      redirect,
      from,
      remember
    });
  }
  return (
    <Root onSubmit={handleSubmit}>
      <div className={classes.errortext}>
        {error && <strong>{error}</strong>}
      </div>
      <div>
        <Field
          name="username"
          autoFocus
          component={RenderTextField}
          label="用户名"
          normalize={trim}
          validate={required}
        />
      </div>
      <div>
        <Field
          name="password"
          component={RenderTextField}
          type="password"
          label="密码"
          validate={required}
        />
      </div>
      <div className={classes.buttonLine}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={pristine || submitting}
        >
          登录
        </Button>
        {/* 非绑定微信登录时 */}
        {!bindWechat && (
          <WeixinLoginDialog
            code={code}
            from={from}
            redirect={redirect}
            token={token}
          />
        )}
      </div>
      <div className={classes.buttonLine}>
        <Field
          id="loginRemember"
          name="remember"
          component={RenderSwitch}
          label="一周内免登录"
        />
      </div>
    </Root>
  );
};

export default compose(reduxForm())(LoginForm);
