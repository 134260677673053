import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Message from './containers/Message';
import themeProvider from './services/themeProvider';
import compose from 'recompose/compose';

import AuthRoute from './services/AuthRoute';
import Home from './containers/Home';
import Login from './containers/account/Login';
import Reg from './containers/account/Reg';
import AuthLogin from './containers/sso/AuthLogin';
import AuthReg from './containers/sso/AuthReg';
import AuthLogout from './containers/sso/AuthLogout';
import AuthBindLogin from './containers/sso/AuthBindLogin';
import AuthBind from './containers/sso/AuthBind';
import CountDownRedirect from './containers/common/CountDownRedirect';

import AppHeader from './containers/AppHeader';
import AppFooter from './containers/AppFooter';

class App extends React.Component {
  componentDidUpdate() {
    console.log('App did update');
  }
  render() {
    return (
      <React.Fragment>
        <AppHeader />
        <Switch>
          <Route exact path="/sso/redirect" component={CountDownRedirect} />
          <Route exact path="/sso/about" component={CountDownRedirect} />
          {/* 第三方授权登录、注册、登出 */}
          <Route exact path="/sso/auth/login" component={AuthLogin} />
          <Route exact path="/sso/auth/reg" component={AuthReg} />} />
          <Route exact path="/sso/auth/logout" component={AuthLogout} />
          {/* 第三方账号绑定sso账号 */}
          <Route exact path="/sso/auth/bind" component={AuthBindLogin} />
          {/* 当前sso账号绑定的第三方应用管理 */}
          <Route exact path="/sso/auth/mybinds" component={AuthBind} />
          {/* 直接登录、注册 */}
          <Route exact path="/sso/login" component={Login} />
          <Route exact path="/sso/reg" component={Reg} />
          {/* AuthRoute为登录后才能访问的路由 */}
          <AuthRoute path="/" component={Home} />
        </Switch>
        <Message />
        <AppFooter />
      </React.Fragment>
    );
  }
}

export default compose(
  withRouter,
  themeProvider
)(App);

// export default withRouter(connect(mapStateToProps)(App));
