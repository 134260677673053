import React from 'react';
import compose from 'recompose/compose';

import '../assets/css/nx.css'; // 自定义的一些样式

const AppFooter = () => {
  return (
    <div className="nx-footer">
      ©2017-
      {new Date().getFullYear()} 上海逆行信息科技有限公司
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://beian.miit.gov.cn/"
      >
        沪ICP备17035474号-2
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://beian.mps.gov.cn/#/query/webSearch"
      >
        沪公网安备31011402006033号
      </a>
    </div>
  );
};
export default compose()(AppFooter);
