import React, { PureComponent } from 'react';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import BasicInfoForm from '../../forms/account/BasicInfoForm';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  CardActionArea,
  CardActions,
  Button,
  Avatar
} from '@mui/material';
import { Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { actions as commonActions } from '../../reducers/common';
import {
  types as accountTypes,
  actions as accountActions
} from '../../reducers/account';
import { actions as ssoActions } from '../../reducers/sso';
import qs from 'qs';
import WeixinLoginDialog from '../../components/weixinLoginDialog';

const PREFIX = 'InfoBasic';

const classes = {
  user_title: `${PREFIX}-user_title`,
  flex_center: `${PREFIX}-flex_center`,
  avatar: `${PREFIX}-avatar`,
  siteWrap: `${PREFIX}-siteWrap`,
  site: `${PREFIX}-site`,
  tipText: `${PREFIX}-tipText`
};

const Root = styled('div')(() => ({
  [`& .${classes.user_title}`]: {
    padding: '10px 0 30px',
    fontSize: '1.5rem',
    display: 'flex',
    color: '#444',
    alignItems: 'center'
  },

  [`& .${classes.flex_center}`]: {
    display: 'flex',
    alignItems: 'center'
  },

  [`& .${classes.avatar}`]: {
    marginRight: '5px',
    width: '34px',
    height: '34px',
    display: 'inline-block'
  },

  [`& .${classes.siteWrap}`]: {
    display: 'flex',
    gap: '20px',
    flexWrap: 'wrap',
    paddingBottom: '100px'
  },

  [`& .${classes.site}`]: {
    flex: 1,
    maxWidth: '400px',
    minWidth: '200px'
  },

  [`& .${classes.tipText}`]: {
    fontSize: '1.4rem',
    color: '#555',
    marginTop: '30px'
  }
}));

class InfoBasic extends PureComponent {
  constructor(props) {
    super(props);
    const { redirect, code } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });

    this.state = {
      redirect,
      code
    };

    console.log('constructor code :>> ', code);
  }
  componentDidMount() {
    const { userid, basicInfo, sites, dispatch } = this.props;

    const { code } = this.state;
    console.log('componentDidMount code :>> ', code, userid);

    if (!basicInfo) {
      // store中的account basic info还没有获取或者为空，在页面加载的时候获取一次
      dispatch(
        accountActions.getBasicInfo(() => {
          this.bindWechat();
        })
      );
    } else {
      this.bindWechat();
    }
    if (!sites) {
      dispatch({ type: accountTypes.SAGA_GET_BINDS_REQUEST });
    }
  }

  // 绑定微信，需要先获取用户信息userid
  bindWechat = () => {
    const { userid, dispatch } = this.props;
    const { code } = this.state;
    console.log('code, userid :>> ', code, userid);
    if (code && userid) {
      // 如果有code，说明是微信登录跳回来的，则当前用户绑定登录的微信账号
      dispatch({
        type: accountTypes.SAGA_BIND_WECHAT_REQUEST,
        code,
        userid,
        resolve: () => {
          dispatch(commonActions.showMessage('绑定微信成功', 'info'));
        }
      });
      window.history.replaceState(null, '', global.location.pathname);
    }
  };

  handleSubmit = values => {
    const { redirect } = this.state;
    console.log(JSON.stringify(values));
    return new Promise(resolve => {
      this.props.dispatch(
        accountActions.setBasicInfoRequest(resolve, values, redirect)
      );
    });
  };

  // 进入指定系统查看
  gotoUrl = site => {
    global.location = site.url;
  };

  // 授权登录指定系统
  authLogin = site => {
    console.log('symbol :>> ', site.symbol);
    if (!(site.symbol && site.jwturl)) {
      return this.props.dispatch(
        commonActions.showMessage('系统信息配置不全，请联系管理员', 'error')
      );
    }
    return new Promise(resolve => {
      this.props.dispatch(
        ssoActions.saga_auth_use_current_user_request(
          resolve,
          site.symbol, // from
          site.jwturl // redirect
        )
      );
    });
  };
  render() {
    const {
      username,
      userid,
      basicInfo,
      // binds,
      sites,
      info: { basic = {} }
    } = this.props;

    console.log('userid :>> ', userid);
    const wxinfo = basic.wxinfo ? JSON.parse(basic.wxinfo) : {};

    // 最后一次是微信登录，且username是自定生成的格式，则当前账号只有微信登录信息，询问是否绑定用户名密码。
    // 如果有账号则直接登录绑定当前微信信息；如果没有可以注册并绑定当前微信信息
    const onlyWechat =
      basic &&
      basic.lastLoginType === 'wechat' &&
      /sso_\d+_\d+/.test(basic.username);
    // const onlyWechat = true; // 测试
    return (
      <Root>
        <Card>
          {onlyWechat ? (
            <CardContent sx={{ textAlign: 'center' }}>
              <Typography align="center" variant="h6" sx={{ p: '20px 0' }}>
                当前为微信登录，可绑定账号或注册账号进行绑定
              </Typography>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/sso/login/"
              >
                绑定已有账号
              </Button>
              <Button
                sx={{ ml: '20px' }}
                color="primary"
                component={Link}
                to="/sso/reg/"
              >
                注册账号并绑定
              </Button>
            </CardContent>
          ) : (
            <CardContent>
              <Grid container spacing={2} className={classes.user_title}>
                <Grid item xs={6} sm={3}>
                  用户名：
                  {username}
                </Grid>
                <Grid item xs={6} sm={3}>
                  用户id： {userid}
                </Grid>
                <Grid item xs={12} sm={6} className={classes.flex_center}>
                  绑定微信：
                  {wxinfo.nickname ? (
                    <div className={classes.flex_center}>
                      <Avatar
                        alt="Remy Sharp"
                        src={wxinfo.headimgurl}
                        className={classes.avatar}
                      />
                      {wxinfo.nickname}
                    </div>
                  ) : (
                    <WeixinLoginDialog>绑定微信</WeixinLoginDialog>
                  )}
                </Grid>
              </Grid>
              <BasicInfoForm
                enableReinitialize
                onSubmit={this.handleSubmit}
                initialValues={basicInfo}
              />
            </CardContent>
          )}
        </Card>
        <div>
          <Typography align="center" variant="h6" sx={{ p: '50px 0 20px' }}>
            可授权登录的第三方系统
          </Typography>
          <div className={classes.siteWrap}>
            {sites &&
              sites.map(site => (
                <Card key={site.id} className={classes.site}>
                  <CardActionArea
                    sx={{
                      height: 'calc(100% - 50px)',
                      verticalAlign: 'top'
                    }}
                    onClick={() => this.gotoUrl(site)}
                  >
                    {/* <CardMedia
                    component="img"
                    alt="Contemplative Reptile"
                    className={classes.media}
                    height="140"
                    image="/static/images/cards/contemplative-reptile.jpg"
                    title="Contemplative Reptile"
                  /> */}
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        {site.name}
                      </Typography>
                      <Typography component="p">{site.intro}</Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions sx={{ justifyContent: 'flex-end' }}>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => this.gotoUrl(site)}
                    >
                      查看
                    </Button>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => this.authLogin(site)}
                    >
                      授权登录
                    </Button>
                  </CardActions>
                </Card>
              ))}
          </div>
        </div>
      </Root>
    );
  }
}

InfoBasic.propTypes = {};

function mapStateToProps(state) {
  return {
    username: state.account.username,
    userid: state.account.id,
    basicInfo: state.account.info.basic,
    info: state.account.info,
    binds: state.account.binds,
    sites: state.account.sites
  };
}

export default compose(connect(mapStateToProps))(InfoBasic);
