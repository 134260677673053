import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import qs from 'qs';
import LoginForm from '../../forms/account/LoginForm';
import { Box, Card, CardContent, Typography, Grid } from '@mui/material';
import grey from '@mui/material/colors/grey';
import compose from 'recompose/compose';
import { actions as ssoActions } from '../../reducers/sso';
import history from '../../history';

class AuthLogin extends PureComponent {
  constructor(props) {
    super(props);
    const { from, token, redirect, authOk } = qs.parse(
      this.props.location.search,
      {
        ignoreQueryPrefix: true
      }
    );
    // if (!(from && token && redirect && authOk)) { // 登录前哪有token?
    if (!(from && token && redirect)) {
      // 绑定时有token，带原系统的jwt
      // if (!(from && redirect)) {
      history.push(
        `/sso/redirect?content=${encodeURIComponent(
          '授权认证信息不全'
        )}&to=${encodeURIComponent(redirect)}`
      );
    }
    this.state = {
      subTitle: `--针对${from}进行授权绑定`,
      token,
      redirect,
      from,
      authOk
    };
  }

  handleSubmit = values => {
    const { from, token, redirect, authOk } = this.state;
    return new Promise(resolve => {
      this.props.dispatch(
        ssoActions.saga_auth_bind_request(
          resolve,
          values,
          from,
          redirect,
          token,
          authOk
        )
      );
    });
  };
  render() {
    return (
      <Grid
        sx={{ height: '80%' }}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Card sx={{ width: '30rem' }}>
            <CardContent>
              <Box sx={{ m: '2rem 0' }}>
                <Typography align="center" variant="h4">
                  授权绑定登录
                </Typography>
                <Typography align="right" color="textSecondary">
                  {this.state.subTitle}
                </Typography>
              </Box>
              <LoginForm
                form="authLoginForm"
                initialValues={{ username: '', remember: true }}
                onSubmit={this.handleSubmit}
              />
              <Typography
                align="right"
                sx={{
                  color: grey[500],
                  marginTop: '2rem'
                }}
              >
                没有账户,
                <Link to="/sso/reg">注册</Link>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.account.username
  };
}
export default compose(connect(mapStateToProps))(AuthLogin);
