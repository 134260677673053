// 一些辅助函数
import { actions as accountActions } from '../reducers/account';
import md5 from 'md5';
import Cookies from 'js-cookie';

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/**
 * client端启动前加载本地用户token信息，设置相关state
 */
export const initAuthInfoAtStart = dispatch => {
  // todo 修改为cookie 存储
  // decode token， state中dispatch设置username active 等信息，判断过期时间等
  const username = Cookies.get('username');
  const active = Number.parseInt(Cookies.get('active'), 10);
  if (username) {
    dispatch(accountActions.userAuth(username, active));
    // 如果本地保存有用户信息，则从服务器获取用户实时相关信息，避免本地信息过期或被篡改
    // todo 暂时取消下行，因为后端中间件会检查对比cookie和session信息，不一致则返回401
    dispatch(accountActions.checkUserAuth());
  }
};

/**
 * 重构每个表单的submit函数，避免重复代码
 */
export const formSubmit = (action, dispatch) => values =>
  new Promise(resolve => {
    dispatch({ type: action, resolve, values });
  });

/**
 *
 * @param {string|Buffer|Array|Uint8Array} message
 * @return {string} md5值，32位
 */
export const md5Passwd = message => md5(message);
