// 扩展路由，非授权跳转
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';
import { actions as accountActions } from '../reducers/account';
import { actions as commonActions } from '../reducers/common';

function mapStateToProps(state) {
  return { username: state.account.username, active: state.account.active };
}

function mapDispatchToProps(dispatch) {
  return {
    clearAuth: () => {
      dispatch(accountActions.clearAuth());
    },
    alertMsg: message => {
      dispatch(commonActions.showMessage(message, 'error'));
    }
  };
}

// 需授权路由列表
const homePaths = [
  '/sso/account/info',
  '/sso/account/password',
  '/sso/account/mybinds',
  '/tools/cdn',
  '/tools/cdnRefresh',
  '/tools/imagemin'
];
const AuthRoute = props => {
  const {
    component: Component,
    username,
    active,
    location,
    clearAuth,
    alertMsg,
    ...rest
  } = props;
  if (username) {
    if (active === 1 && homePaths.indexOf(location.pathname) === -1) {
      // 重定向到用户资料设置页; 参数带过去
      return <Redirect to={'/sso/account/info' + location.search} />;
    }
    if (active === 2) {
      clearAuth();
      alertMsg('用户被禁用，请联系管理员'); // alert => Message
      return <Redirect to="/sso/login" />;
    }
    return <Route {...rest} render={props => <Component {...props} />} />;
  }
  // 需要登录的页面如果没登录的直接转到登录页面
  const _to = {
    pathname: '/sso/login',
    state: {
      from: location
    }
  };
  return <Redirect to={_to} />;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthRoute);
