import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { ReactComponent as WeixinIcon } from '../assets/images/weixin.svg';

const PREFIX = 'WeixinLoginDialog';

const classes = {
  leftIcon: `${PREFIX}-leftIcon`,
  closeButton: `${PREFIX}-closeButton`,
  container: `${PREFIX}-container`
};

// 触发按钮
const WeixinButton = styled(Button)(() => ({
  marginLeft: '6rem',
  backgroundColor: '#44b549',
  '&:hover': {
    backgroundColor: '#358239'
  },
  [`& .${classes.leftIcon}`]: {
    marginRight: '.5rem',
    fontSize: 20
  }
}));

// 标题
const WXDialogTitle = styled(DialogTitle)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  margin: 0,
  padding: theme.spacing(2)
}));

const WXDialogTitleClose = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(0.5),
  top: theme.spacing(0.5),
  color: theme.palette.grey[500]
}));

// 内嵌二维码容器
const QRContainer = styled('div')(() => ({
  width: '30rem',
  height: '40rem',
  margin: '5rem 3rem 0'
}));

// 添加微信授权js
function addExternalScript(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => resolve();
    script.onerror = () => reject(new Error('load error'));
    document.body.appendChild(script);
  });
}

// 创建二维码
function createQrCode(from, redirect, token) {
  const appid = 'wx1455e854f094abf4'; // 网站云监控appid
  let redirect_uri = encodeURIComponent(
    `https://i.desmix.com${global.location.pathname}`
  ); // 跳回当前页面

  // 页面上取是否记住登录，记住保持登录状态一周，否则1个小时
  const rememberValue =
    document.getElementById('loginRemember') &&
    document.getElementById('loginRemember').checked
      ? '1'
      : '';
  if (from && redirect) {
    // 有sso授权参数，就带上
    redirect_uri = encodeURIComponent(
      `https://i.desmix.com${
        global.location.pathname
      }?from=${from}&redirect=${encodeURIComponent(redirect)}&token=${token ||
        ''}&remember=${rememberValue}`
    );
  }

  // 显示二维码
  new global.WxLogin({
    // 需要显示内嵌二维码的容器id
    id: 'login_container',
    // 应用ID
    appid,
    // 网页默认即可
    scope: 'snsapi_login',
    // 授权成功后回调的url
    redirect_uri,
    // 可设置为简单的随机数加session用来校验
    state: Math.ceil(Math.random() * 1000),
    // 二维码的样式，提供"black"、"white"可选。
    style: 'black',
    // 自定义样式链接
    href: ''
  });
}

class WeixinLoginDialog extends React.Component {
  state = {
    open: false,
    wxjsLoaded: false
  };

  // 打开扫码
  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };

  // 关闭扫码
  handleClose = () => {
    this.setState({ open: false });
  };

  // wxjsLoaded => true
  handleWxjsLoaded = () => {
    this.setState({ wxjsLoaded: true });
  };

  render() {
    const { open, wxjsLoaded } = this.state;
    const { from, redirect, token, children } = this.props;

    if (open) {
      if (!wxjsLoaded || !global.WxLogin) {
        addExternalScript(
          'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js'
        )
          .then(() => {
            console.log('External script loaded.');
            this.handleWxjsLoaded();
          })
          .catch(() => {
            console.error('Failed to load external script.');
          });
        // 有WxLogin方法，且还没有初始化过
      } else if (global.WxLogin) {
        setTimeout(() => {
          createQrCode(from, redirect, token);
        }, 10); // 等dom创建好
      }
    }
    return (
      <div>
        {/* <SaveIcon className={classes.leftIcon} /> */}
        <WeixinButton
          variant="contained"
          color="primary"
          onClick={this.handleClickOpen}
          startIcon={<WeixinIcon />}
        >
          {children ? children : '微信登录'}
        </WeixinButton>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <WXDialogTitle id="customized-dialog-title">
            <Typography variant="p">微信扫码登录</Typography>
            <WXDialogTitleClose
              aria-label="Close"
              onClick={this.handleClose}
              size="large"
            >
              <CloseIcon />
            </WXDialogTitleClose>
          </WXDialogTitle>
          <DialogContent>
            <QRContainer id="login_container" />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              取消
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default WeixinLoginDialog;
