import { fork, take, put, call } from 'redux-saga/effects';
import axios from 'axios';
import { stopSubmit } from 'redux-form';
// import history from '../history';
import { types as toolsTypes } from '../reducers/tools';

// 上传CDN
function* uploadCDN() {
  while (true) {
    const { resolve, values } = yield take(toolsTypes.UPLOAD_CDN);

    const formData = new FormData();
    for (const key in values) {
      if (Object.hasOwnProperty.call(values, key)) {
        const element = values[key];
        formData.append(key, element);
      }
    }
    const response = yield axios.post('/uploadCDN', formData);
    if (response.code === 200) {
      yield call(resolve, response.data);
    } else {
      yield put(stopSubmit('uploadCDNForm', { _error: response.error }));
    }
  }
}

// 刷新CDN
function* refreshCDN() {
  while (true) {
    const { resolve, values } = yield take(toolsTypes.REFRESH_CDN);

    const response = yield axios.post('/refreshCDN', values);
    if (response.code === 200) {
      yield call(resolve, response.data);
    } else {
      yield put(stopSubmit('uploadCDNForm', { _error: response.error }));
    }
  }
}

// 图片压缩
function* imageMin() {
  while (true) {
    const { resolve, values, quality } = yield take(toolsTypes.IMAGE_MIN);

    const formData = new FormData();

    for (const key in values) {
      if (Object.hasOwnProperty.call(values, key)) {
        const element = values[key];
        formData.append(key, element);
      }
    }

    const response = yield axios.post(
      '/toolsApi/imageMin?quality=' + quality,
      formData
    );
    if (response.code === 200) {
      yield call(resolve, response.data);
    } else {
      yield put(stopSubmit('uploadCDNForm', { _error: response.error }));
    }
  }
}

export default [fork(uploadCDN), fork(refreshCDN), fork(imageMin)];
