import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import qs from 'qs';
import LoginForm from '../../forms/account/LoginForm';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Button
} from '@mui/material';
import grey from '@mui/material/colors/grey';
import compose from 'recompose/compose';
import { actions as ssoActions } from '../../reducers/sso';
import history from '../../history';

class AuthLogin extends PureComponent {
  constructor(props) {
    super(props);
    /**
     * 原来的参数逻辑：
     * authOk：登录成功回调中间页
     * redirect：登录成功回调中间页处理完后实际跳转页面，用于在第三方页面任意页面登录状态没有后再次授权时回到原来页面
     * 目前暂时只用一个redirect中间页参数，具体再跳转的实际页面前端自行处理
     */
    const { from, token, redirect, authOk } = qs.parse(
      this.props.location.search,
      {
        ignoreQueryPrefix: true
      }
    );
    // if (!(from && token && redirect && authOk)) { // 登录前哪有token? => 绑定用户时有，不在这个页面
    if (!(from && redirect)) {
      history.push(
        `/sso/redirect?content=${encodeURIComponent(
          '授权认证信息不全'
        )}&to=${encodeURIComponent(redirect)}`
      );
    }
    this.state = {
      subTitle: `针对${from}进行授权`,
      token,
      redirect,
      from,
      authOk,
      useCurrentUser: true
    };
  }

  // 使用当前已登录账号授权
  handleUseCurrentUser = () => {
    const { from, token, redirect, authOk } = this.state;
    return new Promise(resolve => {
      this.props.dispatch(
        ssoActions.saga_auth_use_current_user_request(
          resolve,
          from,
          redirect,
          token,
          authOk
        )
      );
    });
  };

  // 使用账号、密码 登录后授权
  handleSubmit = values => {
    const { from, token, redirect, authOk } = this.state;
    return new Promise(resolve => {
      this.props.dispatch(
        ssoActions.saga_auth_login_request(
          resolve,
          values,
          from,
          redirect,
          token,
          authOk
        )
      );
    });
  };
  toggleUseCurrentUser = () => {
    this.setState({ useCurrentUser: !this.state.useCurrentUser });
  };
  render() {
    const { username } = this.props;
    const { useCurrentUser, from, token = '', redirect } = this.state;
    return (
      <Grid
        container
        sx={{
          height: '80%',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Grid item>
          <Card sx={{ width: '30rem' }}>
            <CardContent>
              <Box sx={{ m: '2rem 0' }}>
                <Typography align="center" variant="h5">
                  授权登录
                </Typography>
                <Typography align="center" color="textSecondary">
                  {this.state.subTitle}
                </Typography>
              </Box>
              {username && useCurrentUser ? (
                <div>
                  <Box
                    sx={{
                      m: '1rem 0 2rem',
                      fontSize: '1.5rem'
                    }}
                  >
                    当前登录账号：
                    {username}
                  </Box>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={this.handleUseCurrentUser}
                  >
                    当前账号授权
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ ml: '1rem' }}
                    onClick={this.toggleUseCurrentUser}
                  >
                    切换其他账号
                  </Button>
                </div>
              ) : (
                <LoginForm
                  form="authLoginForm"
                  initialValues={{ username: '', remember: true }}
                  onSubmit={this.handleSubmit}
                />
              )}
              <Typography
                align="right"
                sx={{
                  color: grey[500],
                  mt: '2rem'
                }}
              >
                没有账户,
                <Link
                  to={`/sso/auth/reg?from=${from}&token=${token}&redirect=${encodeURIComponent(
                    redirect
                  )}`}
                >
                  注册
                </Link>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.account.username
  };
}
export default compose(connect(mapStateToProps))(AuthLogin);
